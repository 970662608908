import { Button, Checkbox, DatePicker, Grid, Row, Select } from "antd"
import { useEffect, useMemo, useState } from "react"
import dayjs from "dayjs"
import { DISPLAY_DATE_FORMAT } from "../constant"
import { useTeamLeaveStore } from "@/store/teamLeaveStore"

interface Filter {
  status: string;
  employeeNo: any;
  leaveType: string;
  orderBy: string;
  dateFrom: string | null;
  dateTo: string | null;
  indirect: boolean;
  toAction: boolean;
  showSubmitted: boolean;
  isAscending: boolean;
}

export const DefaultTeamHistoryFilter: Filter = {
  status: "0",
  employeeNo: "all",
  leaveType: "all",
  orderBy: "DateSubmitted",
  dateFrom: null,
  dateTo: null,
  indirect: false,
  toAction: false,
  showSubmitted: false,
  isAscending: true
}

export interface TeamLeaveHistoryFilterProps {
  filter: Filter;
  onFilterChange: (filter: typeof DefaultTeamHistoryFilter) => void
}

export const STATUS_OPTIONS = [
  { label: 'All', value: 'all' },
  { label: 'Pending', value: '0' },
  { label: 'Declined', value: '2' },
  { label: 'Approved', value: '3' },
  { label: 'Cancelled', value: '6' },
];

export const ORDERBY_OPTIONS = [
  { label: 'Start Date', value: 'DateFrom' },
  { label: 'End Date', value: 'DateTo' },
  { label: 'Submitted Date', value: 'DateSubmitted' },
  { label: 'Unit', value: 'Hours' },
];

const TeamLeaveHistoryFilter = (props: TeamLeaveHistoryFilterProps) => {
  const [filter, setFilter] = useState(DefaultTeamHistoryFilter)
  const { state, dispatch } = useTeamLeaveStore(); 

  useEffect(() => {
    // console.log('filter change', filter)
    setFilter({
      ...filter,
      employeeNo: props.filter.employeeNo
    })
  }, [props.filter.employeeNo])

  const employeeOptions = useMemo(() => {
    const employeeList = state.employees.map((employee: any) => ({ label: employee.employeeName, value: employee.employeeNo }))
    employeeList.unshift({ label: 'All', value: 'all' })
    return employeeList
  }, [state.employees])

  const leaveTypeOptions = useMemo(() => {
    const leaveTypes = state.allLeaveCodes.map((leaveCode: any) => ({ label: leaveCode.description, value: leaveCode.leaveCode }))
    leaveTypes.unshift({ label: 'All', value: 'all' })
    return leaveTypes
  }, [state.allLeaveCodes])
  const handleChange = (key: string, value: any) => {
    const newFilter = {
      ...filter,
      [key]: value
    }
    setFilter(newFilter)
    props.onFilterChange(newFilter)
  }

  return (
    <>
      <div className="team-leave-history-filter">
        <div>Status</div>
        <Select
          defaultValue="All"
          value={filter.status}
          onChange={(value) => handleChange("status", value)}
          options={STATUS_OPTIONS}
        ></Select>
        <div>Employee Name</div>
        <Select
          defaultValue="All"
          style={{maxWidth: 170}}
          value={filter.employeeNo}
          onChange={(value) => handleChange("employeeNo", value)}
          options={employeeOptions}
        ></Select>
      
        <div>Leave Type</div>
        <Select
          defaultValue="All"
          style={{maxWidth: 170}}
          value={filter.leaveType}
          onChange={(value) => handleChange("leaveType", value)}
          options={leaveTypeOptions}
        ></Select>
      
        <div>Sort By</div>
        <Select
          defaultValue="Submitted Date"
          value={filter.orderBy}
          onChange={(value) => handleChange("orderBy", value)}
          options={ORDERBY_OPTIONS}
        ></Select>
        <div>Ascending</div>
        <Checkbox
          checked={filter.isAscending}
          onChange={(e) => handleChange("isAscending", e.target.checked)}
        />

        <div>Date From</div>
        <DatePicker
          value={filter.dateFrom ? dayjs(filter.dateFrom) : null}
          format={DISPLAY_DATE_FORMAT}
          onChange={(date) => handleChange("dateFrom", date ? date.toISOString() : null)}
        />
        
        <div>Date To</div>
        <DatePicker
          value={filter.dateTo ? dayjs(filter.dateTo) : null}
          format={DISPLAY_DATE_FORMAT}
          onChange={(date) => handleChange("dateTo", date ? date.toISOString() : null)}
        />
        <div>Include Indirect</div>
        <Checkbox onChange={(e) => handleChange("indirect", e.target.checked)}
          checked={filter.indirect}></Checkbox>

        <div>Leave To Action</div>
        <Checkbox
          checked={filter.toAction}
          onChange={(e) => handleChange("toAction", e.target.checked)}
        />

        <div>Show Submitted Date</div>
        <Checkbox
          checked={filter.showSubmitted}
          onChange={(e) => handleChange("showSubmitted", e.target.checked)}
        />
      </div>
      
    </>
  )
}

export default TeamLeaveHistoryFilter;