import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { Row, Select, Button, Typography, Modal, Spin, notification, message } from 'antd';
import { DeleteColumnOutlined, FilterOutlined, LoadingOutlined } from '@ant-design/icons';
import TeamLeaveCard from './TeamLeaveCard';
import remote from '@/remote/remote';
import { useTeamLeaveStore } from '@/store/teamLeaveStore';
import { LeaveHistoryDetails } from '../types';
import TeamLeaveHistoryFilter, { DefaultTeamHistoryFilter, ORDERBY_OPTIONS, STATUS_OPTIONS } from './TeamLeaveHistoryFilter';
import LeaveDetail from '../leaveDetail/LeaveDetail';
import ApplyLeave from '../leaveDetail/ApplyLeave';
import dayjs from 'dayjs';
import { DefaultTeamLeaveHistoryParam, LEAVE_REQUEST_DATE_FORMAT } from '../constant';

const { Text } = Typography;

// interface TeamLeaveTabProps {
//   teamMembers: { label: string; value: string }[];
//   teamLeaveData: { /* Define the structure of leave data here */ }[];
// }
interface PaginationType {
  current: number;
  pageSize: number;
  totalItems: number | null;
  totalPages: number | null;
}

const DefaultPagination: PaginationType = { current: 1, pageSize: 15, totalItems: null, totalPages: null };


const TeamLeaveTab = forwardRef((props, ref ) => {
  const [teamLeaveData, setTeamLeaveData] = useState<LeaveHistoryDetails[]>([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [pagination, setPagination] = useState({...DefaultPagination});
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isLeaveDetailOpen, setIsLeaveDetailOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedLeave, setSelectedLeave] = useState<LeaveHistoryDetails | null>(null);
  const [selectedEmployee, setSelectedEmployee] = useState<number | null>(null);
  const [filter, setFilter] = useState(DefaultTeamHistoryFilter);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);

  const { state, dispatch, updateSelectedLeave, searchLeaveHistory } = useTeamLeaveStore(); 
  const user = window.Affinity?.user;

  useEffect(() => {
    async function fetchDataInit() {
      setIsLoading(true);
      await loadManagerConfig();
      await loadTeamLeaveHistory();
      setIsLoading(false);
    }
    
    fetchDataInit();
  }, [])

  useEffect(() => {
    dispatch({ type: 'SET_LOADING', payload: isLoading });
  }, [isLoading])

  const loadManagerConfig = () => {
    remote.getLeaveManagerConfig(user.employeeNo)
    .then((res: any) => {
      console.log(res);
      state.employees = res.employees
      dispatch({ type: 'SET_LEAVE_CONFIG', payload: res });
      dispatch({ type: 'SET_EMPLOYEES', payload: res.employees });
      dispatch({ type: 'SET_LEAVE_CODES', payload: res.leaveCodes });
      dispatch({ type: 'SET_ALL_LEAVE_CODES', payload: res.allCompanyLeaveCodes });
      const employeeList = res.employees.map((employee: any) => ({ label: employee.employeeName, value: employee.employeeNo }))
      employeeList.unshift({ label: 'All', value: 'all' })
      setTeamMembers(employeeList);
    })
  }

  const loadMoreLeaveHistory = async () => {
    setIsLoading(true);
    const newPagination = { ...pagination, current: pagination.current + 1 }
    setPagination(newPagination);
    await loadTeamLeaveHistory(newPagination);
    setIsLoading(false);
  }

  const loadTeamLeaveHistory = async (paginationInput?: any, newData?: boolean, employeeNo?: number) => {
    console.log('load team leave history', filter);
    let pageNo = 1;
    if (paginationInput) {
      pageNo = paginationInput.current
    }

    const res = await searchLeaveHistory(filter, pageNo, employeeNo)
    if (!paginationInput) {
      setPagination({
        ...pagination,
        totalPages: res.totalPages,
        totalItems: res.totalItems,
      })
    }
    if (newData) {
      setTeamLeaveData([...res.leaveHistory]);
    } else {
      setTeamLeaveData([...teamLeaveData, ...res.leaveHistory]);
    }
  }

  useImperativeHandle(ref, () => ({
    reloadLeaveHistory,
    approveLeave,
  }));

  const reloadLeaveHistory = async () => {
    setIsLoading(true);
    setTeamLeaveData([]);
    const newPagination = { ...DefaultPagination, current: 1 }
    setPagination(newPagination);
    await loadTeamLeaveHistory(newPagination, true);
    setIsLoading(false);
  };

  const onSelectEmployee = async (employeeNo: number) => {
    console.log('selected employee', employeeNo);
    // setSelectedEmployee(employeeNo);
    setFilter({
      ...filter,
      employeeNo: employeeNo,
    })
    
    setTeamLeaveData([]);
    setPagination({...DefaultPagination});
    setIsLoading(true);
    await loadTeamLeaveHistory(null, true, employeeNo);
    setIsLoading(false);
  }

  const openLeaveDetail = (leave: LeaveDetails) => {
    setSelectedLeave(leave);
    setIsLeaveDetailOpen(true);
  }

  const approveLeave = async () => {
    console.log("approve leave", selectedLeave);
    if (!selectedLeave) return;
    setIsModalLoading(true);
    try {
      const res = await updateSelectedLeave("3");
      if (res.response) {
        notification.success({
          message: "Leave Approved",
          description: res.response,
        });
      }
    } catch (error) {
      errorHandler(error, "Cannot Approve Leave", "An error occurred while approving leave");
      return;
    }
    setIsModalLoading(false);
    closeLeaveDetail();
    reloadLeaveHistory();
  };

  const decline = async () => {
    console.log("decline leave", selectedLeave);
    setIsModalLoading(true);
    try {
      const res = await updateSelectedLeave("2");
      console.log("leave declined");
      if (res.response) {
        notification.success({
          message: "Leave Declined",
          description: res.response,
        });
        closeLeaveDetail();
      }
    } catch (error) {
      errorHandler(error, "Cannot Declined Leave", "An error occurred while declining leave");
      return;
    }
    setIsModalLoading(false);
    closeLeaveDetail();
    reloadLeaveHistory();
  };

  const closeLeaveDetail = () => {
    setIsLeaveDetailOpen(false);
    setIsEdit(false);
  }

  // save edit leave
  const saveLeave = async () => {
    if (!selectedLeave) return;
    setIsModalLoading(true);
    try {
      const res = await updateSelectedLeave(selectedLeave.statusCode.toString());
      let messageArray: string[] = []
      if (res.messages?.length > 0) {
        res.messages.forEach((message: any) => {
          console.log('Message', message.message)
          messageArray.push(message.message)
        });
      }
      if (res.messages[0].messageType === 0) {
        notification.error({
          message: "Leave Save Failed",
          description: res.Response ? res.Response : messageArray.join('\n'),
        });
        return;
      } else {
        notification.success({
          message: "Leave Saved",
          description: messageArray.join('\n'),
        });
      }
      
      closeLeaveDetail();
    } catch (error) {
      console.error('error', error);
      errorHandler(error, "Cannot Save Leave", "An error occurred while saving leave");
    }
    setIsModalLoading(false);
    console.log('save leave', selectedLeave)
  }

  const editLeave = () => {
    setIsEdit(true);
  }

  const onFilterChange = (filter: any) => {
    console.log('filter', filter);  
    setFilter(filter);
  }

  const filterModalOk = async () => {
    console.log('filter modal ok');
    setIsFilterOpen(false);
    setTeamLeaveData([]);
    setPagination({...DefaultPagination});
    setIsLoading(true);
    await loadTeamLeaveHistory(null, true);
    setIsLoading(false);
  }

  const getLabelByValue = (options: any[], value: string) => {
    const option = options.find((option) => option.value === value);
    return option ? option.label : value
  }

  const selectedEmployeeName = useMemo(() => {
    const employee = state.employees.find((employee: any) => employee.employeeNo === selectedEmployee);
    return employee ? employee.employeeName : 'All';
  }, [state.employees, selectedEmployee]);

  const employeeFilteredData = useMemo(() => {
    return teamLeaveData.filter((leave) => {
      if (!selectedEmployee) return true;
      return leave.employeeNo === selectedEmployee;
    });
  }, [teamLeaveData, selectedEmployee]);

  const errorHandler = (error: any, message: string, description: string) => {
    if (!error.response.data.ModelState) {
      notification.error({
        message: message,
        description: description,
      });
    }
    const errorDescription = Object.keys(error.response.data.ModelState)
      .reduce((acc, key) => {
        error.response.data.ModelState[key].forEach((message: string) => {
          acc.push(message);
        });
        return acc;
      }, [])
      .join(", ");
    notification.error({
      message: message,
      description: errorDescription,
    });
  }
  return (
    <>
      <div style={{ background: "white", padding: "20px" }}>
        <Row justify="space-between" align="middle">
          <Select
            style={{ minWidth: 160, maxWidth: 220 }}
            placeholder="Select Team Member"
            value={filter.employeeNo}
            options={teamMembers}
            popupMatchSelectWidth={false}
            onChange={onSelectEmployee}
          />
          <Button icon={<FilterOutlined />} onClick={() => setIsFilterOpen(true)}>Filters</Button>
        </Row>
        <Text
          style={{
            marginTop: "4px",
            display: "inline-block",
            fontSize: "12px",
            textAlign: "center",
          }}
        >
          {
            selectedEmployeeName !== 'All'
            ? <span>Showing {employeeFilteredData.length} leave applications of {selectedEmployeeName} </span>
            : <span>Showing {employeeFilteredData.length} leave applications out of {pagination.totalItems} </span>
          }
          
          with a status of {getLabelByValue(STATUS_OPTIONS, filter.status)}, sorted by {getLabelByValue(ORDERBY_OPTIONS, filter.orderBy)}
        </Text>
      </div>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 42 }} spin />} 
        spinning={state.isLoading}
        size="large" >
        <div style={{ padding: "10px 20px", minHeight: 500 }}>
          {employeeFilteredData.map((leave, index) => {
            return <TeamLeaveCard key={index} leave={leave} index={index} showDetail={openLeaveDetail} />
          })}
          {
            (pagination.totalPages && pagination.totalPages > pagination.current) ? (
              <div className="text-center">
                <Button style={{ margin: "20px 0" }} onClick={loadMoreLeaveHistory}>
                  Load More 
                </Button>
              </div>
            ) : null
          }
        </div>
      </Spin>
      <Modal 
        title="Filter"
        width={350}
        open={isFilterOpen}
        onCancel={() => setIsFilterOpen(false)}
        onOk={() => filterModalOk()}>
        <TeamLeaveHistoryFilter onFilterChange={onFilterChange} filter={filter}/>
      </Modal>
      <Modal
        className='full-screen-modal'
        title="Leave Detail"        
        open={isLeaveDetailOpen}
        onCancel={closeLeaveDetail}
        footer={(
          <>
            <Button type='primary' style={{
              backgroundColor: '#05aa06',
            }} onClick={() => approveLeave()}>Approve</Button>

            <Button type='primary' danger onClick={() => decline()}>Decline</Button>
            {
              isEdit && <Button type='primary' onClick={() => saveLeave()}>Save</Button>
            }
            {
              !isEdit && <Button type='primary'
              style={{ backgroundColor: "#44b5ec"}}
              onClick={() => editLeave()}>Edit</Button>
            }
            
            <Button onClick={closeLeaveDetail}>Close</Button>
          </>
          
        )}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 42 }} spin
          />} spinning={isModalLoading}
          size="large">
          {
            isEdit ? <ApplyLeave isTeamLeave={true} leave={selectedLeave}></ApplyLeave>
             : <LeaveDetail isManager={true} isTeamLeave={true} leave={selectedLeave}></LeaveDetail>
          }
          </Spin>
        
      </Modal>
    </>
  );
});

export default TeamLeaveTab;