import { Select, Typography } from 'antd';
import { useEffect } from 'react';
const { Text } = Typography;

interface EmployeeSelectionProps { isTeamLeave: boolean; isEdit: boolean; employeeList: any[]; selectedEmployee: any; onSelectEmployee: (value: any) => void; leave: any; }

export const EmployeeSelection = ({
  isTeamLeave,
  isEdit,
  employeeList,
  selectedEmployee,
  onSelectEmployee,
  leave,
}: EmployeeSelectionProps) => {
  if (isEdit) {
    return (
      <section style={{ padding: "0 10px", marginTop: "20px" }}>
        <Text strong>Employee</Text>
        <div style={{ marginTop: "8px" }}>{leave?.employeeName}</div>
      </section>
    );
  }

  if (isTeamLeave) {
    return (
      <section style={{ padding: "0 10px", marginTop: "20px" }}>
        <Text strong>Employee</Text>
        <Select
          style={{ width: "100%", marginTop: "8px" }}
          size="large"
          showSearch
          options={employeeList}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          value={selectedEmployee}
          onChange={onSelectEmployee}
          placeholder="Select an employee"
        />
      </section>
    );
  }

  // my leave
  return (
    <section style={{ padding: "0 10px", marginTop: "20px" }}>
       <Text strong>Employee</Text>
      <div style={{ marginTop: "8px" }}>{leave?.employeeName}</div>
    </section>
  );
};