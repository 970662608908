import React from 'react';
import { Typography, Input, Divider } from 'antd';
import theme from '@/styles/customTheme';

const { Text } = Typography;
const { TextArea } = Input;

interface CommentsSectionProps {
  isTeamLeave: boolean;
  isEdit: boolean;
  selectedLeave: any;
  setSelectedLeave: (leave: any) => void;
}

const CommentsSection: React.FC<CommentsSectionProps> = ({
  isTeamLeave,
  isEdit,
  selectedLeave,
  setSelectedLeave,
}) => {
  return (
    <>
      <section style={{ marginTop: "10px", padding: "0 10px" }}>
        <Text strong>Comments</Text>
        <TextArea
          style={{
            marginTop: "8px",
            boxShadow: theme.color.shadow1,
            border: "none",
          }}
          value={selectedLeave.comment}
          onChange={(e) => setSelectedLeave({...selectedLeave, comment: e.target.value})}
          rows={4}
          placeholder="Enter your comment"
        />
      </section>
      {isTeamLeave && isEdit && (
        <>
          <Divider className="leave-divider" />
          <section style={{ marginTop: "10px", padding: "0 10px" }}>
            <Text strong>Manager Comments</Text>
            <TextArea
              style={{
                marginTop: "8px",
                boxShadow: theme.color.shadow1,
                border: "none",
              }}
              value={selectedLeave.managerComment}
              onChange={(e) => setSelectedLeave({...selectedLeave, managerComment: e.target.value})}
              rows={4}
              placeholder="Enter your comment"
            />
          </section>
        </>
      )}
    </>
  );
};

export default CommentsSection;
