import React, { useEffect, useState, useMemo } from 'react';
import { DatePicker, Button, List, Row, Col, Card, Collapse, Typography, Select, DatePickerProps } from 'antd';
import MyEstimateLeave from './myEstimateLeave';
import TeamEstimateLeave from './teamEstimateLeave';

const { Title } = Typography;
interface EstimateLeaveProps {
  canManage: boolean;
}

const EstimateLeave: React.FC<EstimateLeaveProps> = (props: EstimateLeaveProps) => {
  return (
    <div>
      <MyEstimateLeave />
      {
        props.canManage && <TeamEstimateLeave />
      }

    </div>
  );
};

export default EstimateLeave;