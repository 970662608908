import React, { useEffect, useState } from 'react';
import { Spin, Row, Button, Typography, Modal, notification } from 'antd';
import { LoadingOutlined, PaperClipOutlined, CloseOutlined } from '@ant-design/icons';
import theme from '@/styles/customTheme';
import remote from '@/remote/remote';

const { Text } = Typography;
const MAX_FILE_SIZE = 100 * 1024 * 1024; // 100MB
const ALLOWED_TYPES = ['image/jpeg', 'image/png', 'application/pdf'];

interface AttachmentSectionProps {
  selectedLeave: any;
  updateAttachmentFiles: (files: any[]) => void;
}

const AttachmentSection: React.FC<AttachmentSectionProps> = ({
  selectedLeave,
  updateAttachmentFiles,
}) => {
  const [attachmentLoading, setAttachmentLoading] = useState(false);
  const [attachments, setAttachments] = useState<any[]>([]);
  const fileInputRef = React.useRef(null);

  useEffect(() => {
    setAttachments(selectedLeave.attachments)
  }, [selectedLeave.attachments])

  const validateFile = (file: File): boolean => {
    if (file.size > MAX_FILE_SIZE) {
      notification.error({ message: 'File size should be less than 5MB' });
      return false;
    }
    // if (!ALLOWED_TYPES.includes(file.type)) {
    //   notification.error({ message: 'Invalid file type' });
    //   return false;
    // }
    return true;
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file || !validateFile(file)) return;
      // Handle the file attachment logic here
      // onAttachFile(file);
      // if is edit then we already have tsGroupId
      if (selectedLeave.tsGroupId) {
        // upload attachment first
        setAttachmentLoading(true)
        const res = await remote.createLeaveAttachment(selectedLeave.employeeNo, selectedLeave.tsGroupId, file)
        setAttachmentLoading(false)
        updateAttachmentFiles(res.data)
      } else {
        // New leave. upload after submit leave
        const updatedAttachments = [...(selectedLeave.attachments || []), file];
        updateAttachmentFiles(updatedAttachments);
      }
  }
  const confirmDelete = (index: number) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this attachment?',
      onOk: () => deleteAttachment(index),
    });
  };
  const deleteAttachment = async (index: number) => {
    setAttachmentLoading(true);
    try {
      const attachment = selectedLeave.attachments[index];
      if (attachment?.id) {
        await remote.deleteLeaveAttachment(
          selectedLeave.employeeNo,
          selectedLeave.tsGroupId,
          attachment.id
        );
        notification.success({ message: 'Attachment deleted successfully!' });
      }
      const updatedAttachments = selectedLeave.attachments.filter((_, i) => i !== index);
      updateAttachmentFiles(updatedAttachments);
    } catch (error) {
      notification.error({ message: 'Failed to delete attachment!' });
    } finally {
      setAttachmentLoading(false);
    }
  };

  const handleAttachClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }
  return (
    <section style={{ marginTop: "10px", padding: "0 10px" }}>
      <Spin 
        indicator={<LoadingOutlined style={{ fontSize: 42 }} spin />} 
        spinning={attachmentLoading}
        size='large'>
        <Row justify={"space-between"} align={"middle"}>
          <Text strong>Attachments</Text>
          <div>
            <Button
              type="text"
              icon={<PaperClipOutlined />}
              style={{ color: theme.color.gray1 }}
              onClick={handleAttachClick}
            >
              ATTACH FILE
            </Button>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
          </div>
        </Row>
        <div className='mt-2 '>
          {attachments?.map((attachment: any, index: number) => (
            <div key={index} className='d-flex align-center'>
              <PaperClipOutlined />
              <span>{attachment.name}</span>
              <Button
                type="link"
                icon={<CloseOutlined />}
                onClick={() => confirmDelete(index)}
              >
              </Button>
            </div>
          ))}
        </div>
      </Spin>
    </section>
  );
};

export default AttachmentSection;
