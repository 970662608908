import React, { useEffect, useState } from 'react';
import { Card, Typography, Button, DatePicker, Input, Divider, Row, Col, Select, Spin } from 'antd';
import { useTeamLeaveStore } from '@/store/teamLeaveStore';
import { LoadingOutlined, PaperClipOutlined, SearchOutlined } from '@ant-design/icons';
import theme from '@/styles/customTheme';
import remote from '@/remote/remote';
import dayjs from 'dayjs';
import { LIST_DATE_FORMAT } from '../constant';
import { useMyLeaveStore } from '@/store/myLeaveStore';

const { Title, Text } = Typography;
const { TextArea } = Input;

interface ApplyLeaveProps {
  isManager: boolean;
  isTeamLeave: boolean;
  leave: any;
}

const LeaveDetail = (props: ApplyLeaveProps) => {
  // const [employeeList, setEmployeeList] = useState<any[]>([]);
  // const [applyLeaveSettings, setApplyLeaveSettings] = useState<any>({});
  const [leave, setLeave] = useState<any>({});
  const [applyOptions, setApplyOptions] = useState<any>({});
  const [employeeName, setEmployeeName] = useState('');
  const [submittedToName, setSubmittedToName] = useState('');
  const [attachments, setAttchments] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const {state, dispatch} = useTeamLeaveStore();
  const myStore = useMyLeaveStore();
  const myState = myStore.state;
  const myDispatch = myStore.dispatch;
  // useEffect(() => {
  //   const mappedEmployee = state.employees.map((employee) => {
  //     return {
  //       value: employee.employeeNo,
  //       label: employee.employeeName
  //     }
  //   })
  //   console.log(mappedEmployee);
  //   setEmployeeList(mappedEmployee);
  // }, state.employees)

  useEffect(() => {
    const fetchLeaveDetail = async () => {
      setIsLoading(true);
      let res;
      try {
        res = await remote.getLeaveDetail(props.leave.employeeNo, props.leave.tsGroupId);
      } catch (error) {
        console.error('Error fetching leave detail:', error);
        return;
      }
      if (!res) return;
      console.log('leave detail res', res);
      setAttchments(res.attachments);
      props.leave.attachments = res.attachments;
      console.log('props.leave', props.leave);
      props.leave.reply = res.leaveHeader.reply;
      props.leave.partDayReason = res.leaveHeader.partDayReason;
      props.leave.reasonCode = res.leaveHeader.reasonCode;
      props.leave.reasonDescription = res.leaveHeader.reasonDescription;
      setLeave(props.leave);
      setSubmittedToName(props.leave.authorisations[0].submittedToName);

      if (!res.leaveHeader.tsGroupId && res.leaveHeader.tSGroupId) {
        res.leaveHeader.tsGroupId = res.leaveHeader.tSGroupId;
      }
      if (props.isTeamLeave) {
        dispatch({ type: 'SET_SELECTED_LEAVE_DETAIL', payload: res.leaveHeader });
        dispatch({ type: 'SET_SELECTED_LEAVE_UNIT', payload: res.components[0] });
      } else {
        myDispatch({ type: 'SET_LEAVE_DETAIL', payload: res.leaveHeader });
        myDispatch({ type: 'SET_SELECTED_LEAVE', payload: res.leaveHeader });
        myDispatch({ type: 'SET_SELECTED_LEAVE_UNIT', payload: res.components[0] });
      }
      setIsLoading(false);
    }

    fetchLeaveDetail();
    
  }, [props.leave])

  function downloadFile(attachment: any): void {
    remote.getLeaveAttachment(leave.employeeNo, leave.tsGroupId, attachment.id, attachment.name)
  }

  return (
    <Spin indicator={<LoadingOutlined style={{ fontSize: 42 }} spin />} spinning={isLoading}
        size="large">
      {props.isManager && props.isTeamLeave && (
        <section style={{ padding: "0 10px", marginTop: "20px" }}>
          {/* <div>Employee</div> */}
          <Title level={2}>{leave.employeeName}</Title>
        </section>
      )}

      <section style={{ marginTop: "10px", padding: "0 10px" }}>
        <Title level={3}>{leave.codeDescription}</Title>
      </section>

      <Divider className="leave-divider" />
      <section style={{ marginTop: "10px", padding: "0 10px" }}>
        <div style={{ marginBottom: "10px" }}>
          <Text strong>Position</Text>
          <div>{leave.positionTitle}</div>
        </div>
      </section>
      <Divider className="leave-divider" />
      <section style={{ marginTop: "10px", padding: "0 10px" }}>
        <div>
          <Text strong>Start Date</Text>
        </div>
        <div style={{ marginTop: "4px" }}>
          <div>{dayjs(leave.dateFrom).format(LIST_DATE_FORMAT)}</div>
        </div>
        <div style={{ marginTop: "10px" }}>
          <div>
            <Text strong>End Date</Text>
          </div>
          <div style={{ marginTop: "4px" }}>
            <div>{dayjs(leave.dateTo).format(LIST_DATE_FORMAT)}</div>
          </div>
        </div>
        <Row
          justify="space-between"
          align="middle"
          style={{ marginTop: "10px" }}
        >
          <Text style={{ fontSize: "16px" }}>
            <span className='bold'>Total</span> {leave.totalHours} hours
          </Text>
        </Row>
        {leave.balance && (
          <Row>
            <Text style={{ fontSize: "16px" }}>
              Balance {leave.balance.currentUnitsBal} hours
            </Text>
          </Row>
        )}

        {/* <Row style={{ marginTop: '10px' }}>
          <Text style={{ fontSize: '16px' }}>
            Balance 56.25 hours
          </Text>
        </Row> */}
      </section>

      <Divider className="leave-divider" />
      <section style={{ marginTop: "10px", padding: "0 10px" }}>
        {/* <Row justify={'space-between'} align={'middle'}> */}
        <Text strong>Attachments</Text>
        {attachments.map((attachment, index) => (
          <div key={index}>
            <PaperClipOutlined />
            <span style={{cursor: 'pointer', marginTop: '4px', marginLeft: '4px'}} onClick={() => downloadFile(attachment)}>{attachment.name}</span>
          </div>
        ))}
        {/* </Row> */}
      </section>
      <Divider className="leave-divider" />
      <section style={{ marginTop: "10px", padding: "0 10px" }}>
        <Text strong>Comments</Text>
        <br></br>
        <Text>{leave.comment}</Text>
        {/* <TextArea style={{ marginTop: '8px', boxShadow: theme.color.shadow1, border: 'none'}} rows={4} placeholder="Enter your comment" /> */}
      </section>
      {
        props.isManager && (
          <section style={{ marginTop: "10px", padding: "0 10px" }}>
          <Text strong>Manager Comments</Text>
          <br></br>
          <Text>{leave.reply}</Text>
          {/* <TextArea style={{ marginTop: '8px', boxShadow: theme.color.shadow1, border: 'none'}} rows={4} placeholder="Enter your comment" /> */}
        </section>
        )
      }
      <Divider className="leave-divider" />
      <section style={{ marginTop: "10px", padding: "0 10px" }}>
        <Text strong>Approver</Text>
        <br></br>
        <Text>{submittedToName}</Text>
        {/* {leave} */}
        {/* <Text>{leave.authorisations[0]}</Text> */}
      </section>
    </Spin>
  );
};

export default LeaveDetail;
