import React from 'react';
import { Select, Typography } from 'antd';

const { Text } = Typography;

interface ApproverSelectionProps {
  approversOptions: any[];
  selectedLeave: any;
  setSelectedLeave: (leave: any) => void;
}

const ApproverSelection: React.FC<ApproverSelectionProps> = ({
  approversOptions,
  selectedLeave,
  setSelectedLeave,
}) => {
  return (
    <section style={{ marginTop: "10px", padding: "0 10px" }}>
      <Text strong>Approver</Text>
      <Select
        options={approversOptions}
        value={selectedLeave.submittedTo}
        style={{ width: "100%", marginTop: "8px" }}
        placeholder="Select an employee"
        onChange={(value) => setSelectedLeave({...selectedLeave, submittedTo: value})}
      ></Select>
    </section>
  );
};

export default ApproverSelection;
