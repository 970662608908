import React from 'react';
import { Typography } from 'antd';

const { Text } = Typography;

interface LeaveTypeSelectionProps {
  applyOptions: any;
  selectedLeave: any;
  chooseLeaveType: (leaveType: any) => void;
}

const LeaveTypeSelection: React.FC<LeaveTypeSelectionProps> = ({ applyOptions, selectedLeave, chooseLeaveType }) => {
  return (
    <section style={{ padding: "0 10px" }}>
      <Text strong>Leave Type</Text>
      <div className="leave-type-row">
        {applyOptions.leaveType?.map((leaveType: any) => {
          return (
            <div
              className={`leave-type-card ${
                leaveType.leaveCode === selectedLeave.leaveCode
                  ? "selected-type-card"
                  : ""
              } `}
              style={{ background: "white" }}
              key={leaveType.leaveCode}
              onClick={() => chooseLeaveType(leaveType)}
            >
              {
                leaveType.displayBalance && (
                  <div>
                    {leaveType.currentUnitsBal}{' '}
                    {`${leaveType.unitType === "H" ? "Hour" : "Days"} available`}
                  </div>
                )
              }
             
              <div>{leaveType.description}</div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default LeaveTypeSelection;
