import React, { useEffect, useRef, useState } from 'react';
import { Button, Tabs, Card, Row, Col, Divider, Typography, Badge, Modal, Select, notification} from 'antd';
import ApplyLeave from './leaveDetail/ApplyLeave';
import MyLeaveTab from './myLeave/MyLeaveTab';
import TeamLeaveTab from './teamLeave/TeamLeaveTab';
import { LoadingOutlined } from '@ant-design/icons';
import { useMyLeaveStore } from '@/store/myLeaveStore';
import EstimateLeave from './estimateLeave/EstimateLeave';
import { useTeamLeaveStore } from '@/store/teamLeaveStore';
import LeaveCalendar from './calendar/LeaveCalendar';

const { Title, Text } = Typography;
const { TabPane } = Tabs;

// todo: fix enum to include estimated leave and leave calendar
enum LeavePan {
  myLeave = 'My Leave',
  teamLeave = 'Team Leave'
}

interface LeaveProps {
  canManageLeave: boolean;
}

const Leave = (props: LeaveProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  // const [leavePan, setLeavePan] = useState<LeavePan>(props.canManageLeave ? LeavePan.teamLeave : LeavePan.myLeave);
  const [leavePan, setLeavePan] = useState<LeavePan>(LeavePan.myLeave);
  const [userLoaded, setUserLoaded] = useState(false);
  const myStore = useMyLeaveStore();
  const myState = myStore.state;
  const myDispatch = myStore.dispatch;
  const teamLeaveTabRef = useRef<{ reloadLeaveHistory: () => void }>(null);
  const myLeaveTabRef = useRef<{ reloadMyLeaveHistory: () => void }>(null);

  // const [teamLeaveList, setTeamLeaveList] = useState([]);

  // add useStore
  const store = useTeamLeaveStore(); 
 
  
  const showCreateLeave = () => {
    console.log('showCreateLeave');
    showModal();
  }
  
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    setIsModalVisible(false);
    console.log('myState.selectedLeave', myState.selectedLeave);
    if(leavePan === 'Team Leave') {      
      teamSaveLeave();
    } else {
      // my leave
      mySaveLeave();
    }
    setIsModalVisible(false);
  };

  const teamSaveLeave = async () => {
    // team leave
    let res = null;
    try {
      res = await store.saveSelectedLeave();
    } catch (error) {
      console.log('Apply Team Leave Failed error', error);
      notification.error({
        message: "Apply Leave Failed",
      });
      return;
    }

    if (res.messages && res.messages.length > 0) {
      const messageElements = res.messages.map((message: any, index: number) => (
        <div key={index}>{message.message}</div>
      ));
      notification.error({
        message: "Apply Leave Failed",
        description: <>{messageElements}</>,
        duration: 0
      });
      return;
    }

    notification.success({
      message: "Apply Leave Successfully",
    });
    if (teamLeaveTabRef.current) {
      teamLeaveTabRef.current.reloadLeaveHistory();
    }
  }

  const mySaveLeave = async () => {
    let res = null;
    try {
      res = await myStore.saveSelectedLeave();
    } catch (error) {
      console.log('Apply My Leave Failed error', error);
      notification.error({
        message: "Apply Leave Failed",
      });
      return;
    }
    console.log('res', res)
    
    if (res.messages && res.messages.length > 0) {
      const messageElements = res.messages.map((message: any, index: number) => (
        <div key={index}>{message.message}</div>
      ));

      if (res.response && res.data) {
        messageElements.unshift(<div key='-1'>{res.response}</div>)
        notification.success({
          message: "Apply Leave Successfully",
          description: <>{messageElements}</>,
          duration: 0
        });
        if (myLeaveTabRef.current) {
          myLeaveTabRef.current.reloadMyLeaveHistory();
        }
        return
      }
      notification.error({
        message: "Apply Leave Failed",
        description: <>{messageElements}</>,
        duration: 0
      });
      return
    }

    notification.success({
      message: "Apply Leave Successfully",
    });
    if (myLeaveTabRef.current) {
      myLeaveTabRef.current.reloadMyLeaveHistory();
    }
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const changeTab = (key: LeavePan) => {
    setLeavePan(key);
  }

  return (
    <div style={{  }} className='Leave-container'>
      <Row justify="space-between" style={{padding: '20px 20px 0 20px'}} align="middle">
        <Title level={2}>Leave</Title>
        <Button type="primary" style={{ fontSize: '16px' }} onClick={showCreateLeave}>Create</Button>
      </Row>

      <Tabs activeKey={leavePan} style={{ margin: '20px 0 0 0' }} onChange={(key) => changeTab(key as LeavePan)}>
        {
          props.canManageLeave &&
          <TabPane tab="Team Leave" key={LeavePan.teamLeave} className='teamleave-pane'> 
            <TeamLeaveTab ref={teamLeaveTabRef}></TeamLeaveTab>
          </TabPane>
        }
        
        <TabPane tab="My Leave" className='myleave-pane' key={LeavePan.myLeave}>
          <MyLeaveTab ref={myLeaveTabRef}></MyLeaveTab> 
        </TabPane>
        <TabPane tab="Estimated Leave" className='estimatedleave-pane' key="3">
          <EstimateLeave canManage={props.canManageLeave}></EstimateLeave>
        </TabPane>
        <TabPane tab="Leave Calendar" className='leavecalendar-pane' key="4">
          <LeaveCalendar canManage={props.canManageLeave}></LeaveCalendar>
        </TabPane>
      </Tabs>
      <Modal className='full-screen-modal'
        title="Apply Leave"
        open={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <ApplyLeave isTeamLeave={leavePan === 'Team Leave'} />
      </Modal>
    </div>
  );
};

export default Leave;
