import React, { useEffect, useState, useMemo, useImperativeHandle, forwardRef } from 'react';
import { Button, Modal, notification, Row, Spin, Typography } from 'antd';
import MyLeaveCard from './MyLeaveCard';
import MyLeaveBalance from './MyLeaveBalance';
import { myleaveMockData } from '@/mockData/mockData';
import remote from '@/remote/remote';
import ApplyLeave from '../leaveDetail/ApplyLeave';
import LeaveDetail from '../leaveDetail/LeaveDetail';
import { LeaveHistoryDetails } from '../types';
import { useMyLeaveStore } from '@/store/myLeaveStore';
import { FilterOutlined, LoadingOutlined } from '@ant-design/icons';
import { LEAVE_REQUEST_DATE_FORMAT, LeaveStatus } from '../constant';
import dayjs, { Dayjs } from 'dayjs';
import MyLeaveHistoryFilter, { DefaultMyHistoryFilter } from './MyLeaveHistoryFilter';

const { Text } = Typography;
const DefaultMyLeaveHistoryParam = {
  StatusCode: 0,
  DateFrom: dayjs().subtract(1, 'year').format(LEAVE_REQUEST_DATE_FORMAT), 
  OrderBy: 'DateSubmitted',
  isAscending: true,
}

interface PaginationType {
  current: number;
  pageSize: number;
  totalItems: number | null;
  totalPages: number | null;
}

const DefaultPagination: PaginationType = { current: 1, pageSize: 15, totalItems: null, totalPages: null };

const MyLeaveTab = forwardRef((props, ref ) => {

  const [myLeaveData, setMyLeaveData] = useState<any[]>([]);
  const [selectedLeave, setSelectedLeave] = useState<any>({});
  const [isLeaveDetailOpen, setIsLeaveDetailOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filter, setFilter] = useState(DefaultMyHistoryFilter);
  const [pagination, setPagination] = useState({...DefaultPagination});
  
  const { state, dispatch, updateSelectedLeave } = useMyLeaveStore(); 
  const user = window.Affinity?.user;
  
  const myStore = useMyLeaveStore();
  const myState = myStore.state;
  const myDispatch = myStore.dispatch;

  useEffect(() => {
    // loadEmployeeLeaveData();
    setDataLoading(true);
    async function fetchData() {
      await getLeaveConfig();
      await getMyLeaveHistory(null, true);
      setDataLoading(false);
    }

    fetchData();
  }, [])

  useEffect(() => {
    console.log('myState.isLoading', myState.isLoading);
    setDataLoading(myState.isLoading);
    setModalLoading(myState.isLoading);
  }, [myState.isLoading])

  const shortLeaveData = useMemo(() => {
    return myLeaveData.filter(leave => {
      const fromDate = dayjs(leave.dateFrom);
      return fromDate.isSame(dayjs(), 'day') || fromDate.isAfter(dayjs(), 'day');
    });
  }, [myLeaveData]);

  const getLeaveConfig = async () => {
    try {
      const res = await remote.getLeaveConfig(user.employeeNo);
      console.log(res);

      dispatch({ type: 'SET_LEAVE_CONFIG', payload: res });
      dispatch({ type: 'SET_LEAVE_CODE', payload: res.leaveCodes });
    } catch (error) {
      console.error('Failed to get leave config:', error);
    }
  }

  const getMyLeaveHistory = async (paginationInput?: any, newData: boolean = false) => {
    let params: any = {...DefaultMyLeaveHistoryParam,
      StatusCode: filter.status === 'all' ? null : filter.status,
      LeaveCode: filter.leaveType === 'all' ? null : filter.leaveType, // todo: change to number??
      DateFrom: filter.dateFrom,
      DdateTo: filter.dateTo,
      OrderBy: filter.orderBy, // date from, date to, date submitted
      isAscending: filter.isAscending,
    }
    let pageNo = 1;
    if (paginationInput) {
      params.pageNo = paginationInput.current
    }

    console.log('arapams', params);
    try {
      const res = await remote.getMyLeaveHistory(user.employeeNo, params);
      console.log('my leave history', res);
      if (!paginationInput) {
        setPagination({
          ...pagination,
          totalPages: res.pageCount,
          totalItems: res.count,
        })
      }
      const newLeaveHistory = res.history.map((leave: LeaveHistoryDetails) => {
        if (leave.tSGroupId !== null) leave.tsGroupId = leave.tSGroupId;
        return {
          employeeName: leave.employeeName,
          employeeNo: leave.employeeNo,
          dateFrom: leave.dateFrom,
          dateTo: leave.dateTo,
          leaveCode: leave.leaveCode,
          codeDescription: leave.codeDescription,
          status: leave.status,
          statusCode: leave.statusCode,
          totalDays: leave.totalDays,
          totalHours: leave.totalHours,
          unitType: leave.unitType,
          comment: leave.comment,
          authorisations: leave.authorisations,
          positionCode: leave.positionCode,
          positionTitle: leave.positionTitle,
          reasonCode: leave.reasonCode,
          reasonDescription: leave.reasonDescription,
          tsGroupId: leave.tSGroupId,
      }});
      if (newData) {
        setMyLeaveData([...newLeaveHistory]);
        return res;
      } else {
        setMyLeaveData([...myLeaveData, ...newLeaveHistory]);
        return res;
      }
    } catch (error) {
      console.error('Failed to get leave history:', error);
    }
  }

  useImperativeHandle(ref, () => ({
    reloadMyLeaveHistory,
    // approveLeave,
  }));

  const reloadMyLeaveHistory = async () => {
    console.log('reload my leave history');
    setDataLoading(true);
    setMyLeaveData([]);
    const newPagination = { ...DefaultPagination, current: 1 }
    setPagination(newPagination);
    await getMyLeaveHistory(newPagination, true);
    setDataLoading(false);
  };

  const loadMoreLeaveHistory = async () => {
    const newPagination = { ...pagination, current: pagination.current + 1 }
    setPagination(newPagination);
    await getMyLeaveHistory(newPagination, false);
  }

  const getBalanceByCode = (leaveCode: string) => {
    return state.leaveCode.find((code: any) => code.leaveCode === leaveCode);
  }

  const editLeave = () => {
    setIsEdit(true);
  }

  const closeLeaveDetail = () => {
    setIsLeaveDetailOpen(false);
    setIsEdit(false);
  }
  
  const moreAction = (selectedLeave: any) => {
    // balance: getBalanceByCode(leave.leaveCode),
    setSelectedLeave(
      {...selectedLeave,
        balance: getBalanceByCode(selectedLeave.leaveCode)
      }
    );
    setIsLeaveDetailOpen(true);
  }

  const onSave = async () => {
    console.log('save leave', selectedLeave);
    setDataLoading(true);
    try {
      const res = await updateSelectedLeave(selectedLeave.statusCode);
      if (res) {
        console.log('res', res);
        handleError(res, 'Failed to save leave'); 
      }
    } catch (error) {
      console.error('Failed to save leave:', error);
      return
    }
    notification.success({
      message: 'Leave saved successfully',
      placement: 'top',
    });
    setDataLoading(false);
    closeLeaveDetail();
    reloadMyLeaveHistory();

  }

  const onCancel = async () => {
    setModalLoading(true);
    try {
      const res = await updateSelectedLeave(LeaveStatus.Cancelled);
      if (res) {
        handleError(res, 'Failed to cancel leave');
      }
      
    } catch (error) {
      console.log(error);
      notification.error({
        message: 'Failed to cancel leave',
        placement: 'top',
      });
    }

    notification.success({
      message: 'Leave cancelled successfully',
      placement: 'top',
    });
    setModalLoading(false);
    closeLeaveDetail();
    reloadMyLeaveHistory();
  }

  function handleError(res: any, title: string) {
    if (res.messages && res.messages.length > 0) {
      const messageElements = res.messages.map((message: any, index: number) => (
        <div key={index}>{message.message}</div>
      ));
      if (res.data && res.response) {
        notification.success({
          message: title,
          description: <>{messageElements.pop(<div key='0'>res.response</div>)}</>,
          duration: 0
        })
      }
      notification.error({
        message: title,
        description: <>{messageElements}</>,
        duration: 0
      });
      return
    }
  }

  const filterModalOk = async () => {
    setIsFilterOpen(false);
    reloadMyLeaveHistory();
  }

  const onFilterChange = (filter: any) => {
    setFilter(filter);
  }

  return (
    <div style={{ borderRadius: "6px", overflow: "hidden" }}>
      <Spin
        spinning={dataLoading}
        indicator={<LoadingOutlined style={{ fontSize: 42 }} spin />}
      >
        <Row className="pane-first-row" justify="space-between" align="middle">
          <div>
            {showAll ? (
              <span className="update-number">{myLeaveData.length}</span>
            ) : (
              <span className="update-number">{shortLeaveData.length}</span>
            )}
            {/* <span className='update-number'>{shortLeaveData.length}</span> */}
            <Text>My Updates</Text>
          </div>
          <div>
            {showAll ? (
              <Button
                style={{ padding: 0, paddingRight: 24 }}
                type="text"
                onClick={() => setShowAll(false)}
              >
                VIEW LESS
              </Button>
            ) : (
              <Button
                style={{ padding: 0, paddingRight: 24 }}
                type="text"
                onClick={() => setShowAll(true)}
              >
                VIEW ALL
              </Button>
            )}
            <Button
              icon={<FilterOutlined />}
              onClick={() => setIsFilterOpen(true)}
            >
              Filters
            </Button>
          </div>
        </Row>
        {showAll ? (
          <>
            {myLeaveData.map((leave, index) => (
              <MyLeaveCard
                key={index}
                leave={leave}
                index={index}
                moreAction={moreAction}
              />
            ))}
            {pagination.totalPages &&
              pagination.totalPages > pagination.current && (
                <div className="text-center">
                  <Button
                    style={{ margin: "20px 0" }}
                    onClick={loadMoreLeaveHistory}
                  >
                    Load More
                  </Button>
                </div>
              )}
          </>
        ) : (
          shortLeaveData.map((leave, index) => (
            <MyLeaveCard
              key={index}
              leave={leave}
              index={index}
              moreAction={moreAction}
            />
          ))
        )}

        <MyLeaveBalance />
      </Spin>

      <Modal
        title="Filter"
        width={350}
        open={isFilterOpen}
        onCancel={() => setIsFilterOpen(false)}
        onOk={() => filterModalOk()}
      >
        <MyLeaveHistoryFilter onFilterChange={onFilterChange} />
      </Modal>
      <Modal
        className="full-screen-modal"
        title="Leave Detail"
        open={isLeaveDetailOpen}
        onCancel={closeLeaveDetail}
        footer={
          <>
            {!isEdit && <Button onClick={() => editLeave()}>Edit</Button>}

            {isEdit && (
              <>
                <Button type="primary" onClick={onSave}>
                  Save
                </Button>
                <Button type="primary" onClick={onCancel} danger>
                  Cancel
                </Button>
              </>
            )}
            <Button onClick={closeLeaveDetail}>Close</Button>
          </>
        }
      >
        <Spin spinning={modalLoading}>
          {isEdit ? (
            <ApplyLeave isTeamLeave={false} leave={selectedLeave}></ApplyLeave>
          ) : (
            <LeaveDetail
              isManager={true}
              isTeamLeave={false}
              leave={selectedLeave}
            ></LeaveDetail>
          )}
        </Spin>
      </Modal>
    </div>
  );
});

export default MyLeaveTab;